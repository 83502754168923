import classNames from 'classnames';
import { Tooltip, type TooltipProps } from '../molecules/Tooltip';

const colorStyles = {
  green: 'border-cf-green-1/20 bg-cf-green-4 text-cf-green-1',
  blue: 'border-cf-blue-2/20 bg-cf-blue-5 text-cf-blue-2',
  yellow: 'border-cf-orange-1/20 bg-cf-orange-4 text-cf-orange-1',
  purple: 'border-cf-blue-4/20 bg-[#322C3D] text-cf-blue-4',
  pink: 'border-cf-pink-1/20 bg-[#491A35] text-cf-pink-1',
  red: 'border-cf-red-1/20 bg-cf-red-4 text-cf-red-1',
  neutral: 'border-cf-gray-5 bg-cf-gray-4 text-cf-light-3',
  light: 'border-cf-light-1 bg-cf-gray-5 text-cf-light-3',
  white: 'border-cf-light-1 bg-cf-gray-5 text-cf-white',
} as const;

type Color = keyof typeof colorStyles;

export { type Color as PillColor };

type PillProps = {
  text?: string;
  Icon?: React.FC;
  color: Color;
  iconColor?: `text-${string}`;
  tooltipContent?: TooltipProps['content'];
  opacity?: `opacity-${string}`;
  maxWidth?: boolean;
};

export default function Pill({
  text,
  Icon,
  color,
  iconColor,
  tooltipContent,
  opacity,
  maxWidth = true,
}: PillProps) {
  const pill = (
    <div className={classNames(maxWidth && 'max-w-[125px]')}>
      <div
        className={classNames(
          'flex items-center rounded-full border',
          'h-[26px] max-w-fit px-1.5 font-aeonikMedium text-12 leading-none',
          colorStyles[color],
          opacity,
        )}
      >
        {Icon && (
          <div className={['neutral', 'light', 'white'].includes(color) ? iconColor : undefined}>
            <Icon />
          </div>
        )}
        {text && <div className="truncate px-0.5">{text}</div>}
      </div>
    </div>
  );

  if (tooltipContent) return <Tooltip content={tooltipContent}>{pill}</Tooltip>;

  return pill;
}
