import { type ChainflipAsset } from '@chainflip/utils/chainflip';
import { isNullish } from '@chainflip/utils/guard';
import {
  type GetBoostPoolCacheQuery,
  type GetBoostSharesByPoolQuery,
} from '@/shared/graphql/generated/graphql';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import { getBoostPoolCacheQuery, getBoostSharesByPoolQuery } from '../queries/boost';

export const useBoostPool = (asset: ChainflipAsset | undefined, fee: number | undefined) => {
  const { data: cachePool, isLoading: isCacheBoostPoolIdLoading } = useGqlQuery(
    getBoostPoolCacheQuery,
    {
      variables: { chainflipAsset: asset as ChainflipAsset, feeTierPips: fee as number },
      context: { clientName: 'statechainCache' },
      select: (queryResult: GetBoostPoolCacheQuery) => queryResult.pools?.nodes[0],
      enabled: Boolean(asset) && !isNullish(fee),
    },
  );

  const { data: boostSharesData, isLoading: isBoostSharesLoading } = useGqlQuery(
    getBoostSharesByPoolQuery,
    {
      variables: {
        asset: asset as ChainflipAsset,
        feeTierPips: fee as number,
      },
      select: (queryResult: GetBoostSharesByPoolQuery) =>
        queryResult.pools?.nodes[0].boostShares.aggregates?.map((agg) => ({
          sum: {
            ...agg.sum,
            lpIdSs58: queryResult.pools?.nodes[0].boostShares?.nodes.find(
              ({ account }) => String(account?.id) === agg.keys?.[0],
            )?.account.idSs58,
          },
        })),
      enabled: Boolean(asset) && !isNullish(fee),
      context: { clientName: 'lpProcessor' },
    },
  );

  return {
    isLoading: isCacheBoostPoolIdLoading || isBoostSharesLoading,
    boostPoolOverview: cachePool,
    boostBalances: cachePool?.balances?.nodes,
    boostSharesAggregates: boostSharesData,
  };
};
