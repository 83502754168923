import React, { type ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export default function ShadowDom({ children }: { children: ReactNode }) {
  const [hasInitialized, setInitialized] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);
  const [shadowPortal, setShadowPortal] = useState<React.ReactPortal>();
  const [shadowRoot, setShadowRoot] = useState<ShadowRoot>();

  useEffect(() => {
    if (!parentRef.current || hasInitialized || parentRef.current.shadowRoot) {
      return;
    }

    setShadowRoot(parentRef.current.attachShadow({ mode: 'open' }));
    setInitialized(true);
  }, [parentRef.current]);

  useEffect(() => {
    if (shadowRoot) {
      setShadowPortal(createPortal(children, shadowRoot));
    }
  }, [shadowRoot]);

  return <div ref={parentRef}>{shadowPortal}</div>;
}
